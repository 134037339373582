import React from 'react';

import { Box, CircularProgress, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { getBetsClient, handleRequest } from '../../../Clients/Accessors';
import { useOutletContext } from 'react-router-dom';
import { Bet, Game } from '../../../Clients/Clients';
import { getUtcDate, isSameDay } from '../../../Utils/DateUtils';
import { GameCard } from './GameCard';
import { BetCard } from './BetCard';
import { Tabs, Tab } from '../../../Components/Tabs';
import { OutletContext } from '../PlayLayout';
import { ErrorCard } from '../../../Components/ErrorCard';
import { Colors } from '../../../Components/Tokens';

export const BetsPage = () => {
  const [gameWeek, setGameWeek] = useState<number>(10);
  const { championship, games: allGames } = useOutletContext<OutletContext>();
  const [games, setGames] = useState<Game[]>();
  const [gamesByGameWeek, setGamesByGameWeek] = useState<{
    [gameWeek: number]: Game[];
  }>();
  const [betsByGameId, setBetsByGameId] = useState<Map<number, Bet>>();

  useEffect(() => {
    if (championship) {
      handleRequest(getBetsClient().getBets(championship.id)).then(
        (response) => {
          if (response.isSuccess) {
            setBetsByGameId(betsById(response.getValue()));
          }
        },
      );
    }
  }, [championship]);

  useEffect(() => {
    if (allGames !== undefined) {
      setGamesByGameWeek(groupGamesByGameWeek(allGames));
      setGameWeek(getNextGameWeek(allGames));
    }
  }, [allGames]);

  useEffect(() => {
    if (gamesByGameWeek !== undefined) {
      setGames(gamesByGameWeek[gameWeek] || []);
    }
  }, [gamesByGameWeek, gameWeek]);

  return (
    <>
      {games === undefined || betsByGameId === undefined ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress sx={{ color: Colors.NightBlue }} />
        </Box>
      ) : Object.keys(gamesByGameWeek || {}).length === 0 ? (
        <ErrorCard text="Aucun match disponible." />
      ) : (
        <>
          <Tabs
            value={gameWeek}
            onChange={(_, gameWeek: number) => {
              setGameWeek(gameWeek);
            }}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              my: 2,
              mx: 'auto',
              maxWidth: { xs: '94%', sm: '70%', md: '50%' },
            }}
          >
            {Object.keys(gamesByGameWeek || {}).map((gameWeek) => (
              <Tab key={gameWeek} label={gameWeek} value={parseInt(gameWeek)} />
            ))}
          </Tabs>
          <Stack alignItems="center">
            {games.map((game, index) =>
              new Date(game.date) < getUtcDate() ? (
                <GameCard
                  game={game}
                  bet={betsByGameId.get(game.id)}
                  key={index}
                />
              ) : (
                <BetCard
                  game={game}
                  bet={betsByGameId.get(game.id)}
                  key={index}
                />
              ),
            )}
          </Stack>
        </>
      )}
    </>
  );
};

function betsById(bets: Bet[]): Map<number, Bet> {
  return bets.reduce(
    (prev, bet) => prev.set(bet.game.id, bet),
    new Map<number, Bet>(),
  );
}

function groupGamesByGameWeek(games: Game[]): { [gameWeek: number]: Game[] } {
  return games.reduce(
    (prevValue, game) => {
      (prevValue[game.gameWeek] = prevValue[game.gameWeek] || []).push(game);
      return prevValue;
    },
    {} as { [gameWeek: number]: Game[] },
  );
}

function getNextGameWeek(games: Game[]): number {
  if (!games || games.length === 0) return 0;

  const today = getUtcDate();

  // Find all game dates after today
  const nextGames: Game[] = [];
  for (const game of games) {
    const gameDate = new Date(game.date);
    if (isSameDay(gameDate, today)) {
      return game.gameWeek;
    } else if (gameDate.getTime() > today.getTime()) {
      nextGames.push(game);
    }
  }

  if (nextGames.length == 0) {
    let maxGameWeek = 1;
    for (const game of games) {
      if (game.gameWeek > maxGameWeek) {
        maxGameWeek = game.gameWeek;
      }
    }
    return maxGameWeek;
  }

  // Find min date among nextGameDates
  let minDate = new Date(nextGames[0].date);
  let gameWeek = nextGames[0].gameWeek;
  for (const game of nextGames) {
    const gameDate = new Date(game.date);
    if (minDate > gameDate) {
      minDate = gameDate;
      gameWeek = game.gameWeek;
    }
  }

  return gameWeek;
}

import { Box, CircularProgress } from '@mui/material';
import { Bet, Game } from '../../../Clients/Clients';
import { useEffect, useState } from 'react';
import { getBetsClient } from '../../../Clients/Accessors';
import { RoomGame } from './RoomGame';
import { Colors } from '../../../Components/Tokens';

interface Props {
  roomId: number;
}

export const RoomGames = ({ roomId }: Props) => {
  const [betsByGameId, setBetsByGameId] = useState<{
    [gameId: number]: Bet[];
  }>();
  const [games, setGames] = useState<Game[]>();

  useEffect(() => {
    getBetsClient()
      .getRoomBets(roomId)
      .then((bets) => {
        setBetsByGameId(groupBetsByGameId(bets));
        setGames(getGamesFromBets(bets));
      });
  }, []);
  return betsByGameId === undefined || games === undefined ? (
    <Box>
      <CircularProgress sx={{ color: Colors.NightBlue }} />
    </Box>
  ) : (
    <Box>
      {games.map((game, index) => (
        <RoomGame key={index} game={game} bets={betsByGameId[game.id]} />
      ))}
    </Box>
  );
};

function groupBetsByGameId(bets: Bet[]): { [gameId: number]: Bet[] } {
  const betsByGameId: { [gameId: number]: Bet[] } = {};

  for (const bet of bets) {
    const existingBets = betsByGameId[bet.game.id] || [];
    existingBets.push(bet);
    betsByGameId[bet.game.id] = existingBets;
  }

  return betsByGameId;
}

function getGamesFromBets(bets: Bet[]): Game[] {
  const gameById: { [gameId: number]: Game } = {};

  for (const bet of bets) {
    gameById[bet.game.id] = bet.game;
  }

  return Object.values(gameById);
}

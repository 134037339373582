import {
  Box,
  Button as MuiButton,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Typography,
} from '@mui/material';
import { Colors } from '../../../Components/Tokens';
import { Formik } from 'formik';
import { getRoomsClient, handleRequest } from '../../../Clients/Accessors';
import { Game, Room } from '../../../Clients/Clients';
import { useOutletContext } from 'react-router-dom';
import { getUtcDate } from '../../../Utils/DateUtils';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import React from 'react';
import { UserAutoComplete } from './UserAutoComplete';
import { OutletContext } from '../PlayLayout';
import { Button } from '../../../Components/Button';
import { TextField } from '../../../Components/TextField';
import { Card } from '../../../Components/Card';
import { UserCard } from './UserCard';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Select } from '../../../Components/Select';

interface Props {
  open: boolean;
  onClose: () => void;
  onCreateRoom: (room: Room) => void;
}

export const CreateRoomModal = ({ open, onClose, onCreateRoom }: Props) => {
  const { championships, games, user } = useOutletContext<OutletContext>();
  const [gameWeeks, setGameWeeks] = useState<number[]>([]);
  const [selectedUsers, setSelectedUsers] = useState(user ? [user] : []);

  useEffect(() => {
    if (games !== undefined) {
      const gameWeeks = getAvailableGameWeeks(games);
      setGameWeeks(gameWeeks);
    }
  }, [games]);

  useEffect(() => {
    if (
      user &&
      !selectedUsers.find((selectedUser) => selectedUser.id === user.id)
    ) {
      setSelectedUsers([user, ...selectedUsers]);
    }
  }, [user]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Card
        sx={{
          width: '60%',
          mx: 'auto',
          borderWidth: 3,
          borderColor: Colors.Gray,
          borderRadius: 4,
          py: 3,
          px: 5,
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            borderBottomWidth: 3,
            borderBottomStyle: 'solid',
            borderBottomColor: Colors.Green,
            width: '50%',
            margin: 'auto',
            pb: 1,
          }}
          variant="h6"
          component="h2"
        >
          Nouvelle room
        </Typography>
        <Formik
          initialValues={{
            name: '',
            startGameWeek: gameWeeks[0] || 1,
            memberIds: selectedUsers.map(({ id }) => id),
            championshipId: undefined,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Un nom est requis pour créer la room'),
            startGameWeek: Yup.number().required(
              'Une game week de début est requise',
            ),
            memberIds: Yup.array().of(Yup.number()),
            championshipId: Yup.number(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            handleRequest(
              getRoomsClient().createRoom({
                ...values,
                championshipId: values.championshipId ?? 0,
                memberIds: selectedUsers.map((user) => user.id),
              }),
            ).then((response) => {
              if (response.isSuccess) {
                onCreateRoom(response.getValue());
              }
              onClose();
              setSubmitting(false);
            });
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
                  <TextField
                    name="name"
                    label="Nom"
                    onChange={handleChange}
                    value={values.name}
                  />

                  <Typography sx={{ mt: 2 }}>Game Week</Typography>
                  <ButtonGroup variant="outlined">
                    {gameWeeks.map((gameWeek) => (
                      <MuiButton
                        name="startGameWeek"
                        value={gameWeek}
                        key={gameWeek}
                        size="large"
                        variant={
                          values.startGameWeek === gameWeek
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => setFieldValue('startGameWeek', gameWeek)}
                      >
                        {gameWeek}
                      </MuiButton>
                    ))}
                  </ButtonGroup>

                  <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel id="championship-select-label">
                      Championnat
                    </InputLabel>
                    <Select
                      labelId="championship-select-label"
                      value={values.championshipId}
                      name="championshipId"
                      label="Championnat"
                      onChange={handleChange}
                    >
                      {championships ? (
                        championships.map((championship) => (
                          <MenuItem
                            value={championship.id}
                            key={championship.id}
                          >
                            {championship.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>Loading...</MenuItem>
                      )}
                    </Select>
                  </FormControl>

                  <UserAutoComplete
                    label="Membres"
                    exceptUsers={user ? [user] : []}
                    sx={{ mt: 3 }}
                    onSelectUser={(user) => {
                      const newUsers = [...selectedUsers, user];
                      setSelectedUsers(newUsers);
                    }}
                  />

                  <Box>
                    {selectedUsers.map((user, index) => (
                      <UserCard
                        key={index}
                        user={user}
                        actionIcon={
                          <HighlightOffIcon sx={{ color: Colors.LightGray }} />
                        }
                        sx={{ mt: 2 }}
                        action={async (user) =>
                          setSelectedUsers(
                            selectedUsers.filter(
                              (selectedUser) => selectedUser.id === user.id,
                            ),
                          )
                        }
                      />
                    ))}
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={isSubmitting}
                    sx={{ mt: 3 }}
                  >
                    Nouvelle goom
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Card>
    </Modal>
  );
};

function getAvailableGameWeeks(games: Game[]): number[] {
  const gameWeeks = new Set<number>();

  const today = getUtcDate();
  for (const game of games) {
    if (new Date(game.date) > today) {
      gameWeeks.add(game.gameWeek);
    }
  }

  return Array.from(gameWeeks).sort();
}

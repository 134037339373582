import { useOutletContext } from 'react-router-dom';
import { RankingOfUser } from '../../../Clients/Clients';
import { OutletContext } from '../PlayLayout';
import { UserRankingGrid } from '../UserRanking/UserRankingGrid';
import { CircularProgress } from '@mui/material';
import { Colors } from '../../../Components/Tokens';

interface Props {
  ranking: RankingOfUser;
}

export const RoomRanking = ({ ranking }: Props) => {
  const { user } = useOutletContext<OutletContext>();

  return user ? (
    <UserRankingGrid ranking={ranking} user={user} sx={{ mt: 3 }} />
  ) : (
    <CircularProgress sx={{ color: Colors.NightBlue }} />
  );
};

import React from 'react';
import './App.css';
import { Box } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { PlayLayout } from './Views/Play/PlayLayout';
import { AuthenticationPage } from './Views/Authentication/AuthenticationPage';
import { LoginView } from './Views/Authentication/LoginView';
import { RegisterView } from './Views/Authentication/RegisterView';
import { BetsPage } from './Views/Play/Bets/BetsPage';
import { UserRankingPage } from './Views/Play/UserRanking/UserRankingPage';
import { RoomsPage } from './Views/Play/Rooms/RoomsPage';
import { ProfilePage } from './Views/Play/Profile/ProfilePage';
import { RoomPage } from './Views/Play/Rooms/RoomPage';

export const App = () => {
  return (
    <Box>
      <Routes>
        <Route path="" element={<PlayLayout />}>
          <Route path="" element={<BetsPage />} />
          <Route path="bets" element={<BetsPage />} />
          <Route path="rooms" element={<RoomsPage />} />
          <Route path="rooms/:roomId" element={<RoomPage />} />
          <Route path="ranking" element={<UserRankingPage />} />
          <Route path="profile" element={<ProfilePage />} />
        </Route>
        <Route path="auth" element={<AuthenticationPage />}>
          <Route path="" element={<LoginView />} />
          <Route path="login" element={<LoginView />} />
          <Route path="register" element={<RegisterView />} />
        </Route>
      </Routes>
    </Box>
  );
};

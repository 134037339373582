import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Bet, Game } from '../../../Clients/Clients';
import { Card } from '../../../Components/Card';
import { Colors } from '../../../Components/Tokens';
import { dateToString } from '../../../Utils/DateUtils';
import { TextField } from '../../../Components/TextField';

interface Props {
  game: Game;
  bets: Bet[];
}

export const RoomGame = ({ game, bets }: Props) => {
  return (
    <Card sx={{ minWidth: 200, my: 1 }} key={game.id} variant="outlined">
      <Box sx={{ display: 'flex', py: 2 }}>
        <Box
          sx={{
            width: '30%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            src={game.teamIn.club.logoFilePath}
            alt="Game's team in logo"
            style={{ height: 50 }}
          />
          <Typography
            sx={{
              fontSize: 18,
              mt: 1,
              textAlign: 'center',
              color: Colors.White,
            }}
          >
            {game.teamIn.club.shortName}
          </Typography>
        </Box>
        <Box sx={{ width: '40%' }}>
          <Typography
            sx={{
              width: 'fit-content',
              margin: 'auto',
              border: '2px solid #3a3a3a',
              borderRadius: 1,
              px: 1,
              mb: 2,
              color: Colors.White,
              boxShadow: 1,
            }}
          >
            {dateToString(new Date(game.date))}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              type="number"
              name="scoreIn"
              disabled
              sx={{ width: 40 }}
              value={game.scoreIn ?? undefined}
            />

            <Typography
              sx={{ lineHeight: 3.2, width: 25, textAlign: 'center' }}
            >
              {' - '}
            </Typography>

            <TextField
              type="number"
              name="scoreOut"
              disabled
              sx={{ width: 40 }}
              value={game.scoreOut ?? undefined}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: '30%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            src={game.teamOut.club.logoFilePath}
            alt="Game's team out logo"
            style={{ height: 50 }}
          />
          <Typography
            sx={{
              fontSize: 18,
              mt: 1,
              textAlign: 'center',
              color: Colors.White,
            }}
          >
            {game.teamOut.club.shortName}
          </Typography>
        </Box>
      </Box>
      <Table>
        <TableBody>
          {bets.map((bet, index) => (
            <TableRow
              key={index}
              sx={{
                backgroundColor: index % 2 === 0 ? Colors.NightBlue : 'inherit',
              }}
            >
              <TableCell
                sx={{
                  width: '30%',
                  textAlign: 'center',
                  border: 'none',
                  color: Colors.White,
                }}
              >
                {bet.gambler.firstName} {bet.gambler.lastName}
              </TableCell>
              <TableCell
                sx={{
                  width: '40%',
                  textAlign: 'center',
                  border: 'none',
                  color: Colors.White,
                }}
              >
                {bet.scoreIn} - {bet.scoreOut}
              </TableCell>
              <TableCell
                sx={{ width: '30%', textAlign: 'center', border: 'none' }}
              >
                {<Points points={computePointsWon(bet)} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

interface PointsProps {
  points: number | undefined;
}
const Points = ({ points }: PointsProps) => {
  if (points === undefined) {
    return <></>;
  }

  return (
    <Box
      sx={{
        backgroundColor: points > 0 ? Colors.Green : Colors.Red,
        color: Colors.White,
        borderRadius: 1,
        width: 'fit-content',
        mx: 'auto',
        px: 1,
      }}
    >
      {`${points > 0 ? '+' : ''}${points}pts`}
    </Box>
  );
};

function computePointsWon(bet: Bet): number | undefined {
  if (
    bet.game.scoreIn === null ||
    bet.game.scoreOut === null ||
    bet.game.scoreIn === undefined ||
    bet.game.scoreOut === undefined
  ) {
    return undefined;
  }

  if (bet.scoreIn == bet.game.scoreIn && bet.scoreOut === bet.game.scoreOut) {
    return 10;
  }

  if (
    (bet.scoreIn < bet.scoreOut && bet.game.scoreIn < bet.game.scoreOut) ||
    (bet.scoreIn > bet.scoreOut && bet.game.scoreIn > bet.game.scoreOut)
  ) {
    return 5;
  }

  return -2;
}

export class Colors {
  public static DarkGray = '#313131';
  public static Gray = '#525252';
  public static LightGray = '#c2c2c2';
  public static Red = '#911d1c';
  public static LightRed = '#ce393a'; // #d32f2f
  public static DarkerRed = '#7f1919';
  public static White = '#e3e3e3';
  public static Black = '#181818';
  public static Green = '#05835b';
  public static DarkerGreen = '#06573e';
  public static Orange = '#e68b2e';
  public static NightBlue = '#282c42';
  public static GrayBlue = '#1e1c32';
  public static DarkBlue = '#161B33';
  public static LightBlue = '#434966';
  public static Brown = '#aa5c0d';

  public static Primary = Colors.NightBlue;
  public static Secondary = Colors.Green;
}

import React from 'react';

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem as MuiListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import StarIcon from '@mui/icons-material/Star';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import GroupIcon from '@mui/icons-material/Group';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../Components/Tokens';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const SideNav = ({ open, setOpen }: Props) => {
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(open);
    };

  return (
    <div>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            top: { xs: '56px', sm: '64px' },
            backgroundColor: Colors.NightBlue,
          },
        }}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem
              text="Mes paris"
              path="/bets"
              icon={<SportsEsportsIcon sx={{ color: 'white' }} />}
            />
            <ListItem
              text="Rooms"
              path="/rooms"
              icon={<GroupIcon sx={{ color: 'white' }} />}
            />
            <ListItem
              text="Classement"
              path="/ranking"
              icon={<StarIcon sx={{ color: 'white' }} />}
            />
          </List>
          <Divider />
          <List>
            <ListItem
              text="Déconnexion"
              path="/auth/login"
              onClick={() => localStorage.removeItem('token')}
              icon={<LogoutIcon sx={{ color: 'white' }} />}
            />
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

interface ListItemProps {
  text: string;
  path: string;
  icon: JSX.Element;
  onClick?: () => void;
}

const ListItem = ({ text, path, icon, onClick }: ListItemProps) => {
  const navigate = useNavigate();

  return (
    <MuiListItem disablePadding>
      <ListItemButton
        onClick={() => {
          if (onClick) {
            onClick();
          }
          navigate(path);
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} sx={{ color: 'white' }} />
      </ListItemButton>
    </MuiListItem>
  );
};

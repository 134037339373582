import React from 'react';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Colors } from '../../Components/Tokens';
import { Card } from '../../Components/Card';
import { LogoTypography, Typography } from '../../Components/Typography';

export const AuthenticationPage = () => {
  return (
    <Box
      sx={{
        p: { xs: '80px 8px', sm: '10vh 10%' },
        height: { xs: 'calc(100vh - 160px)', sm: '80vh' },
        display: { md: 'flex' },
        alignItems: 'center',
        backgroundColor: Colors.White,
      }}
    >
      <Box
        sx={{
          width: { md: '50%' },
          mr: { xs: 0, md: '10%' },
          mb: { xs: 5, md: 8 },
        }}
      >
        <LogoTypography
          variant="h1"
          sx={{
            fontSize: '3em',
            color: Colors.Primary,
            mb: 2,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Filoky
        </LogoTypography>
        <Typography
          sx={{ fontSize: '1.6em', display: { xs: 'none', md: 'block' } }}
        >
          Défies tes potes en pariant sur les matchs de plusieurs championnats
          français, et d'autres à venir...
        </Typography>
      </Box>
      <Card
        sx={{
          maxHeight: { md: '80vh' },
          width: { md: '40%' },
          px: '3%',
          py: 4,
          borderRadius: 3,
          boxShadow: 2,
        }}
      >
        <Outlet />
      </Card>
    </Box>
  );
};

import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { Colors } from './Tokens';

export const TextField = ({
  color,
  disabled,
  sx,
  ...props
}: TextFieldProps) => {
  return (
    <MuiTextField
      {...props}
      sx={{
        ...sx,
        backgroundColor:
          !color || color === 'primary' ? Colors.NightBlue : Colors.LightGray,
        borderRadius: 1,
        '&:hover fieldset': {
          border: disabled
            ? '1px solid rgba(0,0,0,0.25) !important'
            : `2px solid ${Colors.Green} !important`,
        },
        '&:focus-within fieldset, &:focus-visible fieldset': {
          border: disabled
            ? '1px solid rgba(0,0,0,0.25) !important'
            : `2px solid ${Colors.Green} !important`,
        },
        '&:focus-within > label': {
          color: !color || color === 'primary' ? Colors.White : Colors.Black,
        },
        'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
          {
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: '#ffffff',
            transition: 'background-color 5000s ease-in-out 0s',
            'box-shadow': 'inset 0 0 20px 20px #23232329',
          },
      }}
      InputLabelProps={{
        sx: {
          color: Colors.White,
          '&:focus': {
            color: Colors.White,
          },
        },
        shrink: true,
      }}
      InputProps={{
        sx: {
          color: Colors.White,
          backgroundColor:
            !color || color === 'primary' ? Colors.LightBlue : Colors.LightGray,
        },
      }}
    />
  );
};

import { Paper, SxProps, TableBody, TableHead } from '@mui/material';
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '../../../Components/Table';
import { Colors } from '../../../Components/Tokens';
import { getProfilePictureUri } from '../../../Components/Image';
import { RankableOfUser, RankingOfUser, User } from '../../../Clients/Clients';

const RANKING_LIMIT = 10;

interface Props {
  ranking: RankingOfUser;
  user: User;
  sx?: SxProps;
}

export const UserRankingGrid = ({ ranking, user, sx }: Props) => {
  return (
    <TableContainer component={Paper} sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">Joueur</TableCell>
            <TableCell align="right">Valides</TableCell>
            <TableCell align="right">Exactes</TableCell>
            <TableCell align="right">Points</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {buildRankingRows(ranking).map((row, index) => (
            <TableRow
              key={index}
              sx={{
                backgroundColor:
                  row.persona.id === user?.id
                    ? Colors.NightBlue
                    : index % 2 === 0
                    ? Colors.NightBlue
                    : 'inherit',
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ textAlign: 'center' }}
              >
                {index + 1}
              </TableCell>
              <TableCell
                align="right"
                sx={{ display: 'flex', py: 1, alignItems: 'center' }}
              >
                <img
                  src={getProfilePictureUri(row.persona.profilePictureFileName)}
                  style={{
                    height: 40,
                    width: 40,
                    borderRadius: 20,
                    marginRight: 16,
                  }}
                />
                <p>
                  {row.persona.firstName} {row.persona.lastName}
                </p>
              </TableCell>
              <TableCell align="right">{row.validBetsCount}</TableCell>
              <TableCell align="right">{row.exactScoresCount}</TableCell>
              <TableCell align="right">{row.score}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function buildRankingRows(rankingByPosition: {
  [key: string]: RankableOfUser;
}): RankableOfUser[] {
  const ranking = [];

  for (let i = 0; i < RANKING_LIMIT; i++) {
    const row = rankingByPosition[i];
    if (row) {
      ranking.push(rankingByPosition[i]);
    }
  }

  return ranking;
}

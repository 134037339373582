import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getAuthClient, handleRequest } from '../../Clients/Accessors';
import { Link, useNavigate } from 'react-router-dom';
import { TextField } from '../../Components/TextField';
import { Colors } from '../../Components/Tokens';
import { Button } from '../../Components/Button';
import { Alert } from '../../Components/Alert';

export const LoginView = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleLogin = useCallback((email: string, password: string) => {
    return handleRequest(
      getAuthClient().login({ emailAddress: email, password }),
      true,
    ).then((response) => {
      if (response.isSuccess) {
        localStorage.setItem('token', response.getValue().token);
        navigate('/');
      } else {
        setErrorMessage(response.error);
      }
    });
  }, []);

  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          mb: 2,
          pb: 1,
          width: '80%',
          mx: 'auto',
          fontSize: 30,
          textAlign: 'center',
          borderBottom: `3px solid ${Colors.Green}`,
        }}
      >
        Connection
      </Typography>
      <Formik
        initialValues={{ emailAddress: '', password: '' }}
        validationSchema={Yup.object().shape({
          emailAddress: Yup.string()
            .email('Invalid email addres')
            .required('Required'),
          password: Yup.string()
            .min(2, 'Too Short')
            .max(50, 'Too Long')
            .required('Required'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await handleLogin(values.emailAddress, values.password);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <TextField
              type="email"
              name="emailAddress"
              label="Adresse email"
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ mt: 2 }}
              error={!!errors.emailAddress && !!touched.emailAddress}
              helperText={
                errors.emailAddress &&
                touched.emailAddress &&
                errors.emailAddress
              }
              value={values.emailAddress}
            />
            <TextField
              type="password"
              name="password"
              label="Mot de passe"
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ mt: 2 }}
              error={!!errors.password && !!touched.password}
              helperText={
                errors.password && touched.password && errors.password
              }
              value={values.password}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={{ mt: 2 }}
            >
              Connection
            </Button>
            {errorMessage && (
              <Alert sx={{ mt: 2, fontSize: 12 }} severity="error">
                {errorMessage}
              </Alert>
            )}
          </form>
        )}
      </Formik>
      <Typography
        component={Link}
        to="/auth/register"
        sx={{
          display: 'block',
          textAlign: 'center',
          mt: 2,
          color: Colors.White,
        }}
      >
        Pas encore inscrit ? C{"'"}est ici que ça se passe !
      </Typography>
    </Box>
  );
};

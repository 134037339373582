import { Button as MuiButton, ButtonProps, styled } from '@mui/material';
import { Colors } from './Tokens';

export const Button = styled(MuiButton)<ButtonProps>(({ color }) => ({
  backgroundColor: !color || color === 'primary' ? Colors.Green : Colors.Red,
  color: Colors.White,
  '&:hover': {
    backgroundColor:
      !color || color === 'primary' ? Colors.DarkerGreen : Colors.DarkerRed,
  },
}));

import { backendUrl } from '../Constants';
import { Colors } from './Tokens';

interface Props {
  src: string;
  maxHeight: number | undefined;
}

export const Image = ({ src, maxHeight }: Props) => {
  return (
    <img
      src={src}
      style={{
        maxHeight: maxHeight,
        borderRadius: 6,
        backgroundColor: Colors.White,
        boxShadow: '0px 0px 19px -2px rgba(0,0,0,0.6)',
      }}
    />
  );
};

export const defaultProfilePicture = `/images/profile-pictures/default.png`;

export const getProfilePictureUri = (pictureName: string | undefined) => {
  if (pictureName === undefined || pictureName === null)
    return defaultProfilePicture;

  return `${backendUrl}/api/v1/files/${pictureName}`;
};

import React from 'react';
import { useState } from 'react';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Tooltip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ChampionshipSelect } from '../../Components/ChampionshipSelect';
import { Championship, User } from '../../Clients/Clients';
import { SideNav } from './SideNav';
import { Colors } from '../../Components/Tokens';
import { Link } from 'react-router-dom';
import { getProfilePictureUri } from '../../Components/Image';
import { LogoTypography } from '../../Components/Typography';

interface Props {
  championships: Championship[] | undefined;
  user: User | undefined;
  onChangeChampionship: (championship: Championship) => void;
}

export const PlayAppBar = ({
  championships,
  user,
  onChangeChampionship,
}: Props) => {
  const [openSideNav, setOpenSideNav] = useState(false);

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: 1300,
        background: `linear-gradient(90deg, ${Colors.Primary} 0%, #1e2951 100%)`,
      }}
    >
      <Container
        sx={{
          pl: 0,
          justifyContent: 'space-between',
          maxWidth: { lg: 'none' },
        }}
      >
        <Toolbar disableGutters sx={{ pl: 0, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              size="large"
              onClick={() => setOpenSideNav(!openSideNav)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <LogoTypography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mx: 2,
                display: 'flex',
                letterSpacing: '.3rem',
                fontSize: '1.5em',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Filoky
            </LogoTypography>

            <SideNav open={openSideNav} setOpen={setOpenSideNav} />

            <ChampionshipSelect
              championships={championships}
              onChangeChampionship={onChangeChampionship}
            />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Profile">
              <IconButton component={Link} to="/profile" sx={{ p: 0 }}>
                <img
                  style={{ height: 44, width: 44, borderRadius: 22 }}
                  src={getProfilePictureUri(user?.profilePictureFileName)}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Room } from '../../../Clients/Clients';
import { getRoomsClient, handleRequest } from '../../../Clients/Accessors';
import { ErrorCard } from '../../../Components/ErrorCard';
import { RoomView } from './RoomView';
import { Colors } from '../../../Components/Tokens';

export const RoomPage = () => {
  const { roomId } = useParams();
  const [room, setRoom] = useState<Room>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const roomIdInt = parseInt(roomId ?? '');
    if (!isNaN(roomIdInt)) {
      handleRequest(getRoomsClient().getRoom(roomIdInt)).then((response) => {
        if (response.isSuccess) {
          setRoom(response.getValue());
        }
      });
    } else {
      setError(`Something is wrong with the room id ${roomId}`);
    }
  }, []);

  return error ? (
    <ErrorCard text={error} />
  ) : room === undefined ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <CircularProgress sx={{ color: Colors.NightBlue }} />
    </Box>
  ) : (
    <RoomView room={room} />
  );
};

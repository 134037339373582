import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { getAuthClient, handleRequest } from '../../Clients/Accessors';
import { TextField } from '../../Components/TextField';
import { Colors } from '../../Components/Tokens';
import { Button } from '../../Components/Button';
import { Alert } from '../../Components/Alert';

export const RegisterView = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>();

  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          mb: 2,
          mx: 'auto',
          pb: 1,
          width: '80%',
          fontSize: 30,
          textAlign: 'center',
          borderBottom: `3px solid ${Colors.Green}`,
        }}
      >
        Inscription
      </Typography>
      <Formik
        initialValues={{
          emailAddress: '',
          password: '',
          firstName: '',
          lastName: '',
        }}
        validationSchema={Yup.object().shape({
          emailAddress: Yup.string()
            .email('Invalid email addres')
            .required('Required'),
          password: Yup.string()
            .min(2, 'Too Short')
            .max(50, 'Too Long')
            .required('Required'),
          firstName: Yup.string().required('Required'),
          lastName: Yup.string().required('Required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleRequest(getAuthClient().register(values)).then((response) => {
            setSubmitting(false);
            if (response.isSuccess) {
              localStorage.setItem('token', response.getValue().token);
              navigate('/');
            } else {
              setErrorMessage(response.error);
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <TextField
              type="email"
              name="emailAddress"
              label="Adresse email"
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ mt: 2 }}
              error={!!errors.emailAddress && !!touched.emailAddress}
              helperText={
                errors.emailAddress &&
                touched.emailAddress &&
                errors.emailAddress
              }
              value={values.emailAddress}
            />
            <TextField
              type="password"
              name="password"
              label="Mot de passe"
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ mt: 2 }}
              error={!!errors.password && !!touched.password}
              helperText={
                errors.password && touched.password && errors.password
              }
              value={values.password}
            />
            <TextField
              type="text"
              name="firstName"
              label="Prénom"
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ mt: 2 }}
              error={!!errors.firstName && !!touched.firstName}
              helperText={
                errors.firstName && touched.firstName && errors.firstName
              }
              value={values.firstName}
            />
            <TextField
              type="text"
              name="lastName"
              label="Nom"
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ mt: 2 }}
              error={!!errors.lastName && !!touched.lastName}
              helperText={
                errors.lastName && touched.lastName && errors.lastName
              }
              value={values.lastName}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={{ mt: 2 }}
            >
              Inscription
            </Button>
            {errorMessage && (
              <Alert sx={{ mt: 2, fontSize: 12 }} severity="error">
                {errorMessage}
              </Alert>
            )}
          </form>
        )}
      </Formik>
      <Typography
        component={Link}
        to="/auth/login"
        sx={{
          display: 'block',
          textAlign: 'center',
          mt: 2,
          color: Colors.White,
        }}
      >
        Déjà inscrit ? C{"'"}est ici que ça se passe !
      </Typography>
    </Box>
  );
};

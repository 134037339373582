import { Autocomplete, CircularProgress, SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { User } from '../../../Clients/Clients';
import { getUsersClient, handleRequest } from '../../../Clients/Accessors';
import { TextField } from '../../../Components/TextField';

interface Props {
  label: string;
  exceptUsers: User[];
  sx?: SxProps;
  color?: 'primary' | 'secondary';
  onSelectUser: (users: User) => void;
}

export const UserAutoComplete = ({
  label,
  exceptUsers,
  sx,
  color = 'primary',
  onSelectUser,
}: Props) => {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<readonly User[]>([]);

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      await new Promise<void>((r) =>
        setTimeout(() => {
          r();
        }, 1000),
      );
      const maybeUsers = await handleRequest(
        getUsersClient().searchUsers({
          queryString: query ?? '',
          max: 3,
          exceptUserIds: exceptUsers.map((user) => user.id),
        }),
      );

      setLoading(false);

      if (!maybeUsers.isSuccess) return;

      if (active) {
        setOptions(maybeUsers.getValue());
      }
    })();

    return () => {
      active = false;
    };
  }, [query]);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
      onChange={(_, user) => {
        if (user) {
          onSelectUser(user);
        }
      }}
      sx={sx}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          color={color}
          {...params}
          label={label}
          onChange={(event) => {
            if (event?.target?.value) {
              setQuery(event.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

import { Box, SxProps, Typography } from '@mui/material';
import { Colors } from './Tokens';
import { useRef } from 'react';

interface Props {
  text: string;
  textSmall?: string;
  sx?: SxProps;
  icon: (sx: SxProps) => React.ReactNode;
}

export const Chip = ({ text, textSmall, icon, sx = {} }: Props) => {
  const windowWidth = useRef(window.innerWidth);
  return (
    <Box
      sx={{
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.DarkBlue,
        pl: 0.5,
        pr: 1,
        py: 0.5,
        mr: 1,
        color: Colors.White,
        boxShadow: 1,
        ...sx,
      }}
    >
      {icon({ mr: 0.5, height: '20px' })}
      <Typography>
        {windowWidth.current > 600 ? text : textSmall ?? text}
      </Typography>
    </Box>
  );
};

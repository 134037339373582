import { Alert as MuiAlert, AlertProps, styled } from '@mui/material';
import { Colors } from './Tokens';

export const Alert = styled(MuiAlert)<AlertProps>(({ severity }) => ({
  backgroundColor: severity === 'error' ? Colors.LightRed : undefined,
  color: severity === 'error' ? Colors.White : undefined,
  '& .MuiAlert-icon': {
    color: severity === 'error' ? Colors.White : undefined,
  },
}));

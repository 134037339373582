import { Box, CircularProgress, Typography } from '@mui/material';
import { Card } from '../../../Components/Card';
import { UserAutoComplete } from './UserAutoComplete';
import { useEffect, useState } from 'react';
import { Room, User } from '../../../Clients/Clients';
import { UserCard } from './UserCard';
import { getRoomsClient, handleRequest } from '../../../Clients/Accessors';
import { Colors } from '../../../Components/Tokens';

interface Props {
  room: Room;
}

export const RoomSettings = ({ room }: Props) => {
  const [user, setUser] = useState<User>();
  const [invitedUsers, setInvitedUsers] = useState<User[]>();

  useEffect(() => {
    handleRequest(getRoomsClient().getRoomInvitations(room.id)).then(
      (maybeInvites) => {
        if (maybeInvites.isSuccess) {
          setInvitedUsers(maybeInvites.getValue().map((invite) => invite.user));
        }
      },
    );
  }, []);

  const inviteUser = async () => {
    if (user) {
      const maybeNewInvite = await handleRequest(
        getRoomsClient().createInvitation(room.id, user.id),
      );
      if (maybeNewInvite.isSuccess) {
        setInvitedUsers([
          ...(invitedUsers ?? []).concat([maybeNewInvite.getValue().user]),
        ]);
      }
    }
  };

  const cancelInvitation = async (user: User) => {
    await handleRequest(getRoomsClient().cancelInvitation(room.id, user.id));
    setInvitedUsers([
      ...(invitedUsers as User[]).filter((invite) => invite.id !== user.id),
    ]);
  };

  return (
    <Box>
      <Card sx={{ p: 2 }}>
        <Typography sx={{ mb: 2, fontSize: '1.3em' }} variant="h4">
          Invites des amis à rejoindre la partie
        </Typography>
        <UserAutoComplete
          label="Prénom, nom"
          exceptUsers={room.members.concat(invitedUsers ?? [])}
          onSelectUser={setUser}
        />
        {user && (
          <UserCard
            user={user}
            actionLabel="Inviter"
            sx={{ mt: 2 }}
            action={inviteUser}
          />
        )}
      </Card>

      <Card sx={{ p: 2, mt: 3 }}>
        <Typography sx={{ mb: 2, fontSize: '1.3em' }} variant="h4">
          Invitations en attentes
        </Typography>
        {invitedUsers === undefined ? (
          <CircularProgress sx={{ color: Colors.White }} />
        ) : invitedUsers.length > 0 ? (
          invitedUsers.map((invitedUser, index) => (
            <UserCard
              key={index}
              user={invitedUser}
              actionLabel="Annuler"
              action={cancelInvitation}
            />
          ))
        ) : (
          <Typography variant="subtitle1">
            Aucune invitation en attente
          </Typography>
        )}
      </Card>
    </Box>
  );
};

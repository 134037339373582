import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { PlayAppBar } from './PlayAppBar';
import { useState } from 'react';
import { Championship, Game, User } from '../../Clients/Clients';
import { Colors } from '../../Components/Tokens';
import {
  getChampionshipsClient,
  getGamesClient,
  getUsersClient,
  handleRequest,
} from '../../Clients/Accessors';

export const PlayLayout = () => {
  const [championships, setChampionships] = useState<Championship[]>();
  const [championship, setChampionship] = useState<Championship>();
  const [games, setGames] = useState<Game[]>();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (championship) {
      handleRequest(getGamesClient().getGames(championship.id)).then(
        (response) => {
          if (response.isSuccess) {
            setGames(response.getValue());
          }
        },
      );
    }
  }, [championship]);

  useEffect(() => {
    handleRequest(getChampionshipsClient().getChampionships()).then(
      (response) => {
        if (response.isSuccess) {
          setChampionships(response.getValue());
        }
      },
    );
  }, []);

  useEffect(() => {
    handleRequest(getUsersClient().getCurrentUser()).then((maybeUser) => {
      if (maybeUser.isSuccess) {
        setUser(maybeUser.value);
      }
    });
  }, []);

  return (
    <Box>
      <PlayAppBar
        championships={championships}
        user={user}
        onChangeChampionship={(c) => {
          setChampionship(c);
        }}
      />
      <Box
        sx={{
          pt: 8,
          pb: 4,
          backgroundColor: Colors.White,
          minHeight: '87vh',
        }}
      >
        <Outlet context={{ championship, championships, games, user }} />
      </Box>
    </Box>
  );
};

export interface OutletContext {
  championship: Championship | undefined;
  championships: Championship[] | undefined;
  games: Game[] | undefined;
  user: User | undefined;
}

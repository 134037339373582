import { styled } from '@mui/material/styles';
import {
  Tabs as MuiTabs,
  TabsProps,
  Tab as MuiTab,
  TabProps,
} from '@mui/material';
import { Colors } from './Tokens';

export const Tabs = styled(MuiTabs)<TabsProps>(({ theme }) => ({
  padding: 8,
  borderRadius: 8,
  width: 'fit-content',
  boxShadow: theme.shadows[4],
  background: Colors.Green,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiButtonBase-root': {
    color: Colors.White,
  },
}));

export const Tab = styled(MuiTab)<TabProps>(({ theme }) => ({
  borderRadius: 4,
  [theme.breakpoints.up('xs')]: {
    minWidth: 35,
  },
  [theme.breakpoints.up('sm')]: {
    minWidth: 50,
  },
  '&.Mui-selected': {
    backgroundColor: Colors.DarkerGreen,
    color: Colors.White,
    border: 'none',
  },
}));

import { Box } from '@mui/material';
import { Room } from '../../../Clients/Clients';
import { RoomHeader } from './RoomHeader';
import { Tab, Tabs } from '../../../Components/Tabs';
import { useState } from 'react';
import { RoomRanking } from './RoomRanking';
import { RoomGames } from './RoomGames';
import { RoomSettings } from './RoomSettings';

interface Props {
  room: Room;
}

export const RoomView = ({ room }: Props) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Box sx={{ width: { xs: '94%', sm: '70%', md: '50%' }, margin: 'auto' }}>
      <RoomHeader room={room} />
      <Box>
        <Tabs
          value={tabIndex}
          onChange={(_, newTabIndex: number) => {
            setTabIndex(newTabIndex);
          }}
          sx={{ my: 3, mx: 'auto' }}
        >
          <Tab label="Classement" value={0} />
          <Tab label="Matchs" value={1} />
          {room.isAdmin && <Tab label="Réglages" value={2} />}
        </Tabs>
        {tabIndex === 0 && <RoomRanking ranking={room.ranking} />}
        {tabIndex === 1 && <RoomGames roomId={room.id} />}
        {tabIndex === 2 && room.isAdmin && <RoomSettings room={room} />}
      </Box>
    </Box>
  );
};

import {
  InputBase,
  Select as MuiSelect,
  SelectProps,
  styled,
} from '@mui/material';
import { Colors } from './Tokens';

export const Select2 = styled(MuiSelect)<SelectProps>(
  ({ color, disabled }) => ({
    border: 'none',
    '&:hover, &:focus': {
      border: '2px solid gray',
    },
    '& > .MuiInputLabel-root': {
      color:
        !color || color === 'primary'
          ? 'rgba(255,255,255,0.6)'
          : 'rgba(0,0,0,0.6)',
      backgroundColor: 'red',
    },
    '&:focus-within > label': {
      color: !color || color === 'primary' ? Colors.White : Colors.Black,
    },
    '& > .MuiSelect-select': {
      backgroundColor:
        !color || color === 'primary' ? Colors.NightBlue : Colors.LightGray,
      color: !color || color === 'primary' ? Colors.White : Colors.Black,
      '&:hover, &:focus-within': {
        outline: disabled
          ? 'none'
          : '2px solid ' +
            (!color || color === 'primary' ? Colors.DarkGray : Colors.Green),
      },
      '& > input': {},
      '& > input.Mui-disabled': {
        WebkitTextFillColor:
          !color || color === 'primary' ? Colors.White : Colors.Black,
        textAlign: 'center',
      },
      '& > fieldset': {
        border: 'none',
      },
    },
  }),
);

export const Select = ({ color, disabled, sx, ...props }: SelectProps) => {
  return (
    <MuiSelect
      {...props}
      input={<BootstrapInput disabled={disabled} />}
      sx={{
        ...sx,
        '&:hover fieldset, &:focus fieldset': {
          border: disabled
            ? '1px solid rgba(0,0,0,0.25) !important'
            : `2px solid ${Colors.Green} !important`,
        },
        '& + label': {
          color: Colors.White,
        },
        // '&:focus-within fieldset': {
        //   border: disabled
        //     ? '1px solid rgba(0,0,0,0.25) !important'
        //     : `2px solid ${Colors.Green} !important`,
        // },
        // '&.Mui ': {
        //   color:
        //     !color || color === 'primary'
        //       ? `${Colors.Green} !important`
        //       : Colors.Black,
        // },
        '& .MuiInputBase-input': {
          color: Colors.White,
          backgroundColor:
            !color || color === 'primary' ? Colors.LightBlue : Colors.LightGray,
        },
      }}
    />
  );
};

const BootstrapInput = styled(InputBase)(({ theme, disabled }) => ({
  ':root': {
    color: Colors.White,
  },
  'label + &': {
    color: 'red',
  },
  color: 'red',
  '& > label': {
    color: Colors.White,
    // padding: '7.5px 4px 7.5px 5px',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
    padding: '16.5px 14px',
    // padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      border: disabled
        ? '1px solid rgba(0,0,0,0.25) !important'
        : `2px solid ${Colors.Green} !important`,
    },
  },
}));

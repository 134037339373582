enum Environment {
  Development,
  Production,
}

function getEnvironment(): Environment {
  return process.env.NODE_ENV == 'development'
    ? Environment.Development
    : Environment.Production;
}

export const backendUrl =
  getEnvironment() === Environment.Development
    ? 'http://localhost:5000'
    : 'http://api.filoky.fr';

import { User } from '../../../Clients/Clients';
import { Card } from '../../../Components/Card';
import { getProfilePictureUri } from '../../../Components/Image';
import { Typography } from '../../../Components/Typography';
import { Button } from '../../../Components/Button';
import { Colors } from '../../../Components/Tokens';
import { useCallback, useState } from 'react';
import { IconButton, SxProps } from '@mui/material';

interface Props {
  user: User;
  actionLabel?: string;
  actionIcon?: React.ReactNode;
  actionColor?: 'primary' | 'secondary' | 'error';
  sx?: SxProps;
  action: (user: User) => Promise<void>;
}

export const UserCard = ({
  user,
  action,
  sx,
  actionColor,
  actionLabel,
  actionIcon,
}: Props) => {
  const [isActing, setIsActing] = useState(false);

  const act = useCallback(async () => {
    setIsActing(true);
    await action(user);
    setIsActing(false);
  }, [action, user]);

  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: { xs: '100%', sm: '60%' },
        margin: 'auto',
        py: 1,
        border: `2px solid ${Colors.Gray}`,
        ...sx,
      }}
    >
      <img
        style={{ height: 44, width: 44, borderRadius: 22, marginLeft: '8px' }}
        src={getProfilePictureUri(user.profilePictureFileName)}
      />
      <Typography>
        {user.firstName} {user.lastName}
      </Typography>

      {actionIcon ? (
        <IconButton
          disabled={isActing}
          onClick={act}
          sx={{ mr: 2 }}
          color={actionColor}
        >
          {actionIcon}
        </IconButton>
      ) : (
        <Button
          disabled={isActing}
          onClick={act}
          sx={{ mr: 2 }}
          color={actionColor}
        >
          {actionLabel}
        </Button>
      )}
    </Card>
  );
};

import { Box, CircularProgress } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { OutletContext } from '../PlayLayout';
import { UserInfoView } from './UserInfoView';
import { Colors } from '../../../Components/Tokens';
import { Tab, Tabs } from '../../../Components/Tabs';
import { useState } from 'react';
import { ProfileSettings } from './ProfileSettings';
import { ProfileStatistics } from './ProfileStatistics';

export const ProfilePage = () => {
  const { user } = useOutletContext<OutletContext>();
  const [tabIndex, setTabIndex] = useState(0);

  return user === undefined ? (
    <CircularProgress sx={{ color: Colors.NightBlue }} />
  ) : (
    <Box sx={{ width: { xs: '82%', sm: '70%', md: '50%' }, margin: 'auto' }}>
      <UserInfoView user={user} />
      <Box>
        <Tabs
          value={tabIndex}
          onChange={(_, newTabIndex: number) => {
            setTabIndex(newTabIndex);
          }}
          sx={{ my: 3, mx: 'auto' }}
        >
          <Tab label="Statistiques" value={0} />
          <Tab label="Réglages" value={1} />
        </Tabs>
        {tabIndex === 0 && <ProfileStatistics />}
        {tabIndex === 1 && <ProfileSettings />}
      </Box>
    </Box>
  );
};

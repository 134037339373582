import {
  Typography as MuiTypography,
  ThemeProvider,
  TypographyProps,
  createTheme,
  styled,
} from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: ['Lobster', 'sans-serif'].join(','),
  },
});

export const LogoTypography = (props: TypographyProps) => (
  <ThemeProvider theme={theme}>
    <MuiTypography {...props} />
  </ThemeProvider>
);

export const Typography = styled(MuiTypography)<TypographyProps>(() => ({
  fontFamily: 'oswald',
}));

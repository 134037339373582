import React, { useEffect, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { RankingOfUser } from '../../../Clients/Clients';
import {
  getUserRankingClient,
  handleRequest,
} from '../../../Clients/Accessors';
import { useOutletContext } from 'react-router-dom';
import { OutletContext } from '../PlayLayout';
import { UserRankingGrid } from './UserRankingGrid';
import { Colors } from '../../../Components/Tokens';

export const UserRankingPage = () => {
  const { championship, user } = useOutletContext<OutletContext>();
  const [ranking, setRanking] = useState<RankingOfUser>();

  useEffect(() => {
    if (championship) {
      handleRequest(
        getUserRankingClient().getOverallRanking(championship.id),
      ).then((response) => {
        if (response.isSuccess) {
          setRanking(response.getValue());
        }
      });
    }
  }, [championship]);

  return ranking && user ? (
    <UserRankingGrid
      ranking={ranking}
      user={user}
      sx={{
        width: { xs: '96%', sm: '70%' },
        mx: { xs: '1%', sm: '15%' },
        my: 5,
      }}
    />
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <CircularProgress sx={{ color: Colors.NightBlue }} />
    </Box>
  );
};

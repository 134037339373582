import {
  Table as MuiTable,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  styled,
  TableCellProps,
  TableProps,
  TableContainerProps,
  TableRowProps,
} from '@mui/material';
import { Colors } from './Tokens';

export const TableContainer = styled(MuiTableContainer)<TableContainerProps>(
  () => ({
    borderRadius: 8,
  }),
);

export const Table = styled(MuiTable)<TableProps>(({ theme }) => ({
  boxShadow: theme.shadows[2],
  backgroundColor: Colors.NightBlue,
}));

export const TableRow = styled(MuiTableRow)<TableRowProps>(() => ({
  'tbody > &:hover': {
    backgroundColor: Colors.DarkBlue,
  },
}));

export const TableCell = styled(MuiTableCell)<TableCellProps>(() => ({
  justifyContent: 'flex-start',
  flexDirection: 'row',
  color: Colors.White,
  borderColor: Colors.LightGray,
  textAlign: 'left',
  '&.MuiTableCell-head': {
    fontWeight: 'bold',
  },
  '&.MuiTableCell-body': {
    borderBottom: 'none',
  },
}));

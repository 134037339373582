import { Box, IconButton } from '@mui/material';
import { Card } from '../../../Components/Card';
import { Colors } from '../../../Components/Tokens';
import { FieldValues, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  getFilesClient,
  getUsersClient,
  handleRequest,
} from '../../../Clients/Accessors';
import { User } from '../../../Clients/Clients';
import EditIcon from '@mui/icons-material/Edit';
import { Formik } from 'formik';
import { Image, defaultProfilePicture } from '../../../Components/Image';
import { TextField } from '../../../Components/TextField';
import { backendUrl } from '../../../Constants';

interface Props {
  user: User;
}

export const UserInfoView = ({ user }: Props) => {
  const [filePath, setFilePath] = useState(defaultProfilePicture);
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data: FieldValues) => {
    if (!user) return;

    const formData = new FormData();
    formData.append('file', data.file[0]);
    formData.append('type', 'profile-picture');
    const maybeFileName = await handleRequest(
      getFilesClient().saveProfilePicture(user.id, formData),
    );
    if (maybeFileName.isSuccess) {
      setFilePath(
        `${backendUrl}/api/v1/files/${maybeFileName.getValue().fileName}`,
      );
    }
  };

  useEffect(() => {
    if (user?.profilePictureFileName) {
      setFilePath(`${backendUrl}/api/v1/files/${user.profilePictureFileName}`);
    }
  }, [user]);

  return (
    <Card
      sx={{
        display: 'flex',
        mt: 3,
        p: 3,
      }}
    >
      <Box sx={{ position: 'relative', width: 100 }}>
        <Image src={filePath} maxHeight={100} />
        <form onChange={handleSubmit(onSubmit)}>
          <IconButton
            component="label"
            sx={{
              position: 'absolute',
              width: 30,
              height: 30,
              bottom: -8,
              right: -8,
              backgroundColor: Colors.Green,
              '&:hover': {
                backgroundColor: Colors.DarkerGreen,
              },
            }}
          >
            <EditIcon style={{ color: 'white' }} />
            <input
              {...register('file', {
                required: 'Recipe picture is required',
              })}
              type="file"
              hidden
            />
          </IconButton>
        </form>
      </Box>
      <Box sx={{ ml: { xs: 2, sm: 3 } }}>
        <Formik
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
          }}
          onSubmit={({ firstName, lastName }) => {
            handleRequest(
              getUsersClient().updateUserInfo({
                firstName,
                lastName,
              }),
            );
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onBlur={handleSubmit}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <TextField
                  name="firstName"
                  label="Prénom"
                  onChange={handleChange}
                  sx={{ width: '46%' }}
                  value={values.firstName}
                  InputLabelProps={{
                    style: { color: Colors.White },
                  }}
                />

                <TextField
                  name="lastName"
                  label="Nom"
                  onChange={handleChange}
                  sx={{ width: '46%', ml: '8%' }}
                  value={values.lastName}
                  InputLabelProps={{
                    style: { color: Colors.White },
                  }}
                />
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Card>
  );
};

export function getUtcDate(): Date {
  const date = new Date();
  return new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds(),
    ),
  );
}

export function isSameDay(date1: Date, date2: Date): boolean {
  return (
    date1.getUTCFullYear() === date2.getUTCFullYear() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCDate() === date2.getUTCDate()
  );
}

export function dateToString(date: Date): string {
  return `${addZeroIfNecessary(date.getMonth() + 1)}/${addZeroIfNecessary(
    date.getDate(),
  )}/${date.getFullYear()} ${addZeroIfNecessary(
    date.getHours(),
  )}:${addZeroIfNecessary(date.getMinutes())}`;
}

function addZeroIfNecessary(time: number): string {
  return time < 10 ? `0${time}` : time.toString();
}

import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { RoomInvite } from '../../../Clients/Clients';
import { getRoomsClient, handleRequest } from '../../../Clients/Accessors';
import { Colors } from '../../../Components/Tokens';
import { Button } from '../../../Components/Button';

export const UserRoomInvites = () => {
  const [roomInvites, setRoomInvites] = useState<RoomInvite[]>();

  useEffect(() => {
    handleRequest(getRoomsClient().getUserInvitations()).then(
      (maybeRoomInvites) => {
        if (maybeRoomInvites.isSuccess) {
          setRoomInvites(maybeRoomInvites.value);
        }
      },
    );
  }, []);
  return roomInvites !== undefined && roomInvites.length > 0 ? (
    <Stack
      sx={{ width: { xs: '94%', sm: '70%', md: '50%' }, mx: 'auto', mt: 2 }}
      spacing={2}
    >
      {roomInvites.map((roomInvite, index) => (
        <UserRoomInvite
          key={index}
          roomInvite={roomInvite}
          onInviteHandled={() =>
            setRoomInvites([
              ...roomInvites.filter((invite) => invite.id !== roomInvite.id),
            ])
          }
        />
      ))}
    </Stack>
  ) : (
    <></>
  );
};

interface UserRoomInviteProps {
  roomInvite: RoomInvite;
  onInviteHandled: () => void;
}

export const UserRoomInvite = ({
  roomInvite,
  onInviteHandled,
}: UserRoomInviteProps) => {
  const [isAccepting, setIsAccepting] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  const acceptInvite = () => {
    setIsAccepting(true);
    handleRequest(getRoomsClient().acceptInvitation(roomInvite.room.id)).then(
      () => {
        setIsAccepting(false);
        onInviteHandled();
      },
    );
  };

  const cancelInvite = () => {
    setIsCancelling(true);
    handleRequest(getRoomsClient().rejectInvitation(roomInvite.room.id)).then(
      () => {
        setIsCancelling(false);
        onInviteHandled();
      },
    );
  };

  return (
    <Box
      sx={{
        shadow: 2,
        py: 2,
        px: 3,
        backgroundColor: Colors.NightBlue,
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: Colors.White,
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <Typography sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
        Tu es invité à rejoindre <b>"{roomInvite.room.name}"</b>
      </Typography>
      <Box sx={{ display: 'flex', mt: { xs: 1, sm: 0 } }}>
        <Button sx={{ mr: 2 }} disabled={isAccepting} onClick={acceptInvite}>
          Accepter
        </Button>
        <Button color="error" disabled={isCancelling} onClick={cancelInvite}>
          Refuser
        </Button>
      </Box>
    </Box>
  );
};

import React from 'react';

import { Box, Typography } from '@mui/material';
import { Bet, Game } from '../../../Clients/Clients';
import { Card } from '../../../Components/Card';
import { Colors } from '../../../Components/Tokens';
import { dateToString } from '../../../Utils/DateUtils';
import { TextField } from '../../../Components/TextField';

interface Props {
  game: Game;
  bet: Bet | undefined;
}

export const GameCard = ({ game, bet }: Props) => {
  return (
    <Card
      sx={{ minWidth: 200, width: { xs: '94%', sm: '70%', md: '50%' }, my: 1 }}
      key={game.id}
      variant="outlined"
    >
      <Box sx={{ display: 'flex', py: 2 }}>
        <Box
          sx={{
            width: '30%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            src={game.teamIn.club.logoFilePath}
            alt="Game's team in logo"
            style={{ height: 50 }}
          />
          <Typography
            sx={{
              fontSize: 18,
              mt: 1,
              textAlign: 'center',
              color: Colors.White,
            }}
          >
            {game.teamIn.club.shortName}
          </Typography>
        </Box>
        <Box sx={{ width: '40%' }}>
          <Typography
            sx={{
              width: 'fit-content',
              margin: 'auto',
              border: '1px solid #5e5e5e',
              borderRadius: 1,
              px: 1,
              mb: 2,
              color: Colors.White,
              boxShadow: 1,
            }}
          >
            {dateToString(new Date(game.date))}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              type="number"
              name="scoreIn"
              disabled
              sx={{ width: 40 }}
              value={game.scoreIn ?? undefined}
            />

            <Typography
              sx={{ lineHeight: 3.2, width: 25, textAlign: 'center' }}
            >
              {' - '}
            </Typography>

            <TextField
              type="number"
              name="scoreOut"
              disabled
              sx={{ width: 40 }}
              value={game.scoreOut ?? undefined}
            />
          </Box>

          {bet && (
            <Box>
              <Typography
                sx={{
                  width: 'fit-content',
                  margin: 'auto',
                  border: '2px solid #3a3a3a',
                  borderRadius: 1,
                  px: 1,
                  mt: 1,
                  color: Colors.White,
                  boxShadow: 3,
                }}
              >
                {bet.scoreIn} : {bet.scoreOut}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: '30%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            src={game.teamOut.club.logoFilePath}
            alt="Game's team out logo"
            style={{ height: 50 }}
          />
          <Typography
            sx={{
              fontSize: 18,
              mt: 1,
              textAlign: 'center',
              color: Colors.White,
            }}
          >
            {game.teamOut.club.shortName}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

import * as React from 'react';
import { Box, CardContent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Room } from '../../../Clients/Clients';
import { getRoomsClient, handleRequest } from '../../../Clients/Accessors';
import { CreateRoomModal } from './CreateRoomModal';
import { Card } from '../../../Components/Card';
import { Colors } from '../../../Components/Tokens';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Link } from 'react-router-dom';
import { Chip } from '../../../Components/Chip';
import { UserRoomInvites } from './UserRoomInvites';
import { Button } from '../../../Components/Button';

export const RoomsPage = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [rooms, setRooms] = useState<Room[]>();

  useEffect(() => {
    handleRequest(getRoomsClient().getRooms()).then((response) => {
      if (response.isSuccess) {
        setRooms(response.getValue());
      }
    });
  }, []);

  return (
    <>
      <UserRoomInvites />

      <Button
        sx={{
          width: { xs: '94%', sm: '70%', md: '20%' },
          mx: { xs: '3%', sm: '15%', md: '25%' },
          mt: 3,
          mb: 1,
          py: '12px',
          backgroundColor: Colors.Green,
          '&:hover': {
            backgroundColor: Colors.DarkerGreen,
          },
        }}
        onClick={() => setOpenCreateModal(true)}
        variant="contained"
        size="large"
        startIcon={<AddIcon />}
      >
        Nouvelle room
      </Button>

      <CreateRoomModal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        onCreateRoom={(room) => setRooms((rooms) => [...(rooms ?? []), room])}
      />

      {rooms?.map((room, index) => (
        <Card
          key={index}
          sx={{
            minWidth: 275,
            width: { xs: '94%', sm: '70%', md: '50%' },
            mx: 'auto',
            my: 2,
            color: Colors.White,
            transition: 'transform 200ms',
            '&:hover': {
              transform: 'scale(1.01)',
              cursor: 'pointer',
            },
          }}
        >
          <CardContent
            component={Link}
            to={`/rooms/${room.id}`}
            sx={{
              display: 'flex',
              textDecoration: 'none',
              color: Colors.White,
              '&.MuiCardContent-root:last-child': { p: 3 },
            }}
          >
            <img
              src="/images/room-pictures/french-room.png"
              style={{
                width: 80,
                height: 80,
                borderRadius: 6,
                backgroundColor: Colors.White,
                border: `3px solid ${Colors.White}`,
                boxShadow: '0px 0px 19px -2px rgba(0,0,0,0.6)',
              }}
            />
            <Box
              sx={{
                ml: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ fontSize: 25 }}>{room.name}</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Chip
                  icon={(sx) => <PersonIcon sx={sx} />}
                  text={room.members.length + ' membres'}
                  textSmall={room.members.length.toString()}
                  sx={{ border: '2px solid #3a3a3a', py: 0.2 }}
                />
                <Chip
                  icon={(sx) => <EmojiEventsIcon sx={sx} />}
                  text={room.championship.name}
                  sx={{ border: '2px solid #3a3a3a', py: 0.2 }}
                />
                <Chip
                  icon={(sx) => <CalendarMonthIcon sx={sx} />}
                  text={room.startGameWeek + ' → ?'}
                  textSmall={room.startGameWeek.toString()}
                  sx={{ border: '2px solid #3a3a3a', py: 0.2 }}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

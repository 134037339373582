import { Typography } from '@mui/material';
import { Card } from './Card';
import { Colors } from './Tokens';

interface Props {
  text: string;
}

export const ErrorCard = ({ text }: Props) => {
  return (
    <Card
      sx={{
        width: { xs: '95%', md: '50%' },
        p: 3,
        margin: 'auto',
        mt: 6,
        color: Colors.LightGray,
      }}
    >
      <Typography sx={{ textAlign: 'center' }}>{text}</Typography>
    </Card>
  );
};

import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from 'react';
import { Championship } from '../Clients/Clients';
import { Box } from '@mui/material';

interface Props {
  championships: Championship[] | undefined;
  onChangeChampionship: (championship: Championship) => void;
}

export const ChampionshipSelect = ({
  championships,
  onChangeChampionship,
}: Props) => {
  const [currentChampionship, setCurrentChampionship] =
    useState<Championship>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelectChampionship = (championship: Championship) => {
    onChangeChampionship(championship);
    setCurrentChampionship(championship);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (championships && championships.length > 0) {
      setCurrentChampionship(championships[0]);
      onChangeChampionship(championships[0]);
    }
  }, [championships]);

  return championships !== undefined ? (
    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
      <Button
        disableElevation
        onClick={handleClick}
        sx={{ color: 'white' }}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {currentChampionship ? currentChampionship.name : 'Championships'}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {championships.map((championship, index) => (
          <MenuItem
            key={index}
            onClick={() => handleSelectChampionship(championship)}
            disableRipple
          >
            {championship.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  ) : (
    <></>
  );
};

import { Box } from '@mui/material';
import { Colors } from '../../../Components/Tokens';
import { Chip } from '../../../Components/Chip';
import { Room } from '../../../Clients/Clients';
import PersonIcon from '@mui/icons-material/Person';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Typography } from '../../../Components/Typography';

export interface Props {
  room: Room;
}

export const RoomHeader = ({ room }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        mt: 2,
        p: 2,
        backgroundColor: '#ccc',
        borderRadius: 1,
        boxShadow: 1,
      }}
    >
      <img
        src="/images/room-pictures/french-room.png"
        style={{
          width: 86,
          height: 86,
          borderRadius: 6,
          backgroundColor: Colors.White,
          border: `3px solid ${Colors.White}`,
          boxShadow: '0px 0px 19px -2px rgba(0,0,0,0.6)',
        }}
      />
      <Box sx={{ ml: 3 }}>
        <Typography variant="h4" sx={{ color: Colors.Black, mb: 2 }}>
          {room.name}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Chip
            icon={(sx) => <PersonIcon sx={sx} />}
            text={room.members.length + ' membres'}
            textSmall={room.members.length.toString()}
          />
          <Chip
            icon={(sx) => <EmojiEventsIcon sx={sx} />}
            text={room.championship.name}
          />
          <Chip
            icon={(sx) => <CalendarMonthIcon sx={sx} />}
            text={room.startGameWeek + ' → ?'}
            textSmall={room.startGameWeek.toString()}
          />
        </Box>
      </Box>
    </Box>
  );
};

import { Box, Typography } from '@mui/material';
import { Card } from '../../../Components/Card';
import { Colors } from '../../../Components/Tokens';
import { getUsersClient, handleRequest } from '../../../Clients/Accessors';
import { Formik } from 'formik';
import { TextField } from '../../../Components/TextField';
import { Button } from '../../../Components/Button';

export const UpdatePasswordView = () => {
  return (
    <Card
      sx={{
        display: 'flex',
        mt: 3,
        p: 3,
      }}
    >
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
        }}
        onSubmit={({ currentPassword, newPassword }) => {
          handleRequest(
            getUsersClient().updatePassword({
              currentPassword,
              newPassword,
            }),
          );
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Typography
              variant="h6"
              sx={{ mb: 2.5, textAlign: 'center', color: Colors.White }}
            >
              Nouveau mot de passe
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                name="currentPassword"
                label="Mot de passe actuel"
                type="password"
                sx={{ width: '46%' }}
                value={values.currentPassword}
                onChange={handleChange}
                InputLabelProps={{
                  style: { color: Colors.White },
                }}
              />

              <TextField
                name="newPassword"
                label="Nouveau mot de passe"
                type="password"
                sx={{ width: '46%' }}
                value={values.newPassword}
                onChange={handleChange}
                InputLabelProps={{
                  style: { color: Colors.White },
                }}
              />
            </Box>
            <Button
              type="submit"
              sx={{ mt: 3, mx: 'auto' }}
              variant="contained"
            >
              Mettre à jour
            </Button>
          </form>
        )}
      </Formik>
    </Card>
  );
};

import React from 'react';

import { Box, Typography } from '@mui/material';
import { Bet, Game } from '../../../Clients/Clients';
import { Formik } from 'formik';
import { getBetsClient, handleRequest } from '../../../Clients/Accessors';
import { Colors } from '../../../Components/Tokens';
import { dateToString } from '../../../Utils/DateUtils';
import { TextField } from '../../../Components/TextField';
import { Card } from '../../../Components/Card';

interface Props {
  game: Game;
  bet: Bet | undefined;
  key: number;
}

export const BetCard = ({ game, bet }: Props) => {
  return (
    <Card
      sx={{ minWidth: 200, width: { xs: '94%', sm: '70%', md: '50%' }, my: 1 }}
      key={game.id}
      variant="outlined"
    >
      <Box sx={{ display: 'flex', py: 2 }}>
        <Box
          sx={{
            width: '30%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            src={game.teamIn.club.logoFilePath}
            alt="Game's team in logo"
            style={{ height: 50 }}
          />
          <Typography
            sx={{
              fontSize: 18,
              mt: 1,
              textAlign: 'center',
              color: Colors.White,
            }}
          >
            {game.teamIn.club.shortName}
          </Typography>
        </Box>
        <Box sx={{ width: '40%' }}>
          <Typography
            sx={{
              width: 'fit-content',
              margin: 'auto',
              border: '2px solid #3a3a3a',
              borderRadius: 1,
              px: 1,
              mb: 2,
              color: Colors.White,
              boxShadow: 1,
            }}
          >
            {dateToString(new Date(game.date))}
          </Typography>
          <Formik
            initialValues={{
              scoreIn: bet?.scoreIn.toString() ?? '',
              scoreOut: bet?.scoreOut.toString() ?? '',
            }}
            onSubmit={({ scoreIn, scoreOut }) => {
              const scoreInInt = parseInt(scoreIn);
              const scoreOutInt = parseInt(scoreOut);
              if (
                !isNaN(scoreInInt) &&
                !isNaN(scoreOutInt) &&
                scoreInInt >= 0 &&
                scoreOutInt >= 0
              ) {
                handleRequest(
                  getBetsClient().createBet({
                    gameId: game.id,
                    scoreIn: scoreInInt,
                    scoreOut: scoreOutInt,
                  }),
                );
              }
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <form
                onChange={handleSubmit}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <TextField
                    type="number"
                    name="scoreIn"
                    onChange={handleChange}
                    sx={{ width: 40 }}
                    value={values.scoreIn}
                  />

                  <Typography
                    sx={{ lineHeight: 3.2, width: 25, textAlign: 'center' }}
                  >
                    {' - '}
                  </Typography>

                  <TextField
                    type="number"
                    name="scoreOut"
                    onChange={handleChange}
                    sx={{ width: 40 }}
                    value={values.scoreOut}
                  />
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        <Box
          sx={{
            width: '30%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            src={game.teamOut.club.logoFilePath}
            alt="Game's team out logo"
            style={{ height: 50 }}
          />
          <Typography
            sx={{
              fontSize: 18,
              mt: 1,
              textAlign: 'center',
              color: Colors.White,
            }}
          >
            {game.teamOut.club.shortName}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

import { backendUrl } from '../Constants';
import {
  AuthenticationClient,
  BetsClient,
  ChampionshipsClient,
  GamesClient,
  IConfig,
  RoomsClient,
  SwaggerException,
  UserRankingClient,
  UsersClient,
} from './Clients';

export function getAuthClient(): AuthenticationClient {
  return new AuthenticationClient(new IConfig(), backendUrl);
}

export function getRoomsClient(): RoomsClient {
  return new RoomsClient(new IConfig(), backendUrl);
}

export function getChampionshipsClient(): ChampionshipsClient {
  return new ChampionshipsClient(new IConfig(), backendUrl);
}

export function getGamesClient(): GamesClient {
  return new GamesClient(new IConfig(), backendUrl);
}

export function getBetsClient(): BetsClient {
  return new BetsClient(new IConfig(), backendUrl);
}

export function getUsersClient(): UsersClient {
  return new UsersClient(new IConfig(), backendUrl);
}

export function getUserRankingClient(): UserRankingClient {
  return new UserRankingClient(new IConfig(), backendUrl);
}

export function getFilesClient() {
  return {
    saveProfilePicture: (
      userId: number,
      formData: FormData,
    ): Promise<{ fileName: string }> =>
      fetch(`${backendUrl}/api/v1/files/profile-pictures/${userId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      }).then(async (res) => {
        if (res.status === 200) {
          const json = await res.json();
          return json;
        }
        const error = await res.text();
        throw new SwaggerException(error, res.status, error, {}, res);
      }),
  };
}

export class Attempt<T> {
  public value: T | undefined;
  public isSuccess: boolean;
  public error: string | undefined;

  constructor(value: T | undefined, error: string | undefined) {
    this.isSuccess = value !== undefined;
    this.value = value;
    this.error = error;
  }

  public getValue(): T {
    if (!this.isSuccess) {
      throw new Error(this.error);
    }

    return this.value as T;
  }
}

function success<T>(value: T): Attempt<T> {
  return new Attempt<T>(value, undefined);
}

function failure<T>(error: string): Attempt<T> {
  return new Attempt<T>(undefined, error);
}

export async function handleRequest<T>(
  request: Promise<T>,
  isLoginRequest?: boolean,
): Promise<Attempt<T>> {
  try {
    const value = await request;
    return success(value);
  } catch (error) {
    if (SwaggerException.isSwaggerException(error)) {
      if (error.status === 401 && !isLoginRequest) {
        window.location.href = '/auth';
      }
      if (error.result?.Detail) {
        return failure(error.result.Detail);
      }
      return failure(error.message);
    }
    return failure((error as Error)?.message);
  }
}

import { Box } from '@mui/material';
import { UpdatePasswordView } from './UpdatePasswordView';

export const ProfileSettings = () => {
  return (
    <Box>
      <UpdatePasswordView />
    </Box>
  );
};
